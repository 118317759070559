<template>
  <v-menu offset-y offset-x content-class="header--popup-container">
    <template v-slot:activator="{ on, attrs }">
      <div style="position: relative" v-bind="attrs" v-on="on">
        <img src="@/assets/img/icons/notif.svg" class="single--button" />
        <div :class="{ unread: unreadCount > 0 }"></div>
      </div>
    </template>
    <div class="notif--title">
      {{ $t('home.sidebarMenu.notification') }}
    </div>
    <div v-if="notificationList.length > 0">
      <notification-item
        v-for="notification in notificationList"
        :notification="notification"
        :key="`${notification.id}${notification.createdAt}`"
        class="popup--item"
      />
    </div>
    <div class="see--all">
      <router-link to="/notifications">
        {{ $t('home.sidebarMenu.seeAllNotif') }}
      </router-link>
    </div>
  </v-menu>
</template>

<script>
const NotificationItem = () => import('@/components/notification/partials/notification-item');
import { mapState } from 'vuex';
import Pusher from 'pusher-js';
export default {
  name: 'header-notifications',
  data() {
    return {
      channel: null,
    };
  },
  components: {
    NotificationItem,
  },
  computed: {
    ...mapState({
      notificationList: (state) => state.v2.notification.notificationOverviewList,
      user: (state) => state.global.user,
      loading: (state) => state.v2.notification.loadingOverview,
      unreadCount: (state) => state.v2.notification.unreadCount,
    }),
  },
  methods: {
    initNotif() {
      this.$store.dispatch('v2/notification/getOverview');
      this.$store.dispatch('v2/notification/getUnreadCount');
    },
    notifListener: function (notification) {
      const existingNotif = this.notificationList.find((notif) => {
        return notif.id === notification.id;
      });
      if (!existingNotif) {
        this.$store.commit('v2/notification/ADD_NOTIFICATION_OVERVIEW', notification);
        this.$store.commit('v2/notification/ADD_UNREAD_COUNT');
      }
    },
  },
  mounted() {
    if (this.user != null) {
      if (this.$route.path.search('/dashboard/myinbox/message') === -1) {
        this.initNotif();
      }
      // this.$echo.private(`App.Models.Auth.User.${this.user.id}`).notification(this.notifListener);

      let apiUrl = this.$store.state.global.apiUrl;
      var pusherObj = new Pusher('d488ba0661d9f76d10ea', {
        authEndpoint: apiUrl + '/broadcasting/auth',
        cluster: 'ap1',
        encrypted: true,
        auth: {
          headers: {
            Authorization: `Bearer ${this.$store.state.global.token}`,
          },
        },
      });
      this.channel = pusherObj.subscribe(`private-App.Models.Auth.User.${this.user.id}`);
      this.channel.bind(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        this.notifListener,
      );
    }
  },
  destroyed() {
    this.channel.unbind(
      'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
      this.notifListener,
    );
    // this.$echo.private(`App.Models.Auth.User.${this.user.id}`).stopListening(this.notifListener);
  },
};
</script>
