<template>
  <v-menu offset-y offset-x content-class="header--popup-container">
    <template v-slot:activator="{ on, attrs }">
      <div style="position: relative" v-bind="attrs" v-on="on">
        <img src="@/assets/img/icons/mail-1.svg" class="single--button" />
        <div :class="{ unread: unreadCount > 0 }"></div>
      </div>
    </template>
    <div class="notif--title">
      {{ $t('home.sidebarMenu.inbox') }}
    </div>
    <div v-if="inboxList.length > 0">
      <inbox-item
        v-for="inbox in inboxList"
        :inbox="inbox"
        :key="`${inbox.msgThreadUuid}`"
        class="popup--item"
      />
    </div>
    <div class="see--all">
      <router-link to="/inbox">
        {{ $t('home.sidebarMenu.seeAllInbox') }}
      </router-link>
    </div>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';

const InboxItem = () => import('@/components/inbox/partials/inbox-item');
export default {
  name: 'header-notifications',
  components: {
    InboxItem,
  },
  data: () => ({
    // inboxList: ['', '', ''],
  }),
  computed: {
    ...mapState({
      inboxList: (state) => state.v2.myinbox.messageThreadOverview,
      user: (state) => state.global.user,
      unreadCount: (state) => state.v2.myinbox.unreadCount,
    }),
  },
  methods: {
    initNotif() {
      this.$store.dispatch('v2/myinbox/getOverview');
      this.$store.dispatch('v2/myinbox/getUnreadCount');
    },
  },
  mounted() {
    if (this.user != null) {
      // console.log(
      //   "MOUNTED:",
      //   this.$route.path.search("/dashboard/myinbox/message")
      // );
      this.initNotif();
      // this.$echo.private(`App.Models.Auth.User.${this.user.id}`).notification(notification => {
      //   const existingNotif = this.notificationList.find(notif => {
      //     return notif.id === notification.id;
      //   });
      //   if (!existingNotif) {
      //     this.$store.commit('v2/notification/ADD_NOTIFICATION_OVERVIEW', notification);
      //     this.$store.commit('v2/notification/ADD_UNREAD_COUNT');
      //   }
      // });
    }
  },
};
</script>
