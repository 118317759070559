<template>
  <v-menu offset-y offset-x content-class="header--popup-container header--listing-type">
    <template v-slot:activator="{ on, attrs }">
      <div class="listing--type-wrapper mr-4-5" v-bind="attrs" v-on="on">
        <span class="mr-2" v-if="type === 'R'">{{ $t('general.rent') }}</span>
        <span class="mr-2" v-else-if="type === 'S'">{{ $t('general.sell') }}</span>
        <i class="ion-ios-arrow-down"></i>
      </div>
    </template>
    <div class="d-flex">
      <div v-if="propertyTypesResidential">
        <div class="popup--title">
          <span v-if="type === 'R'">{{ $t('general.rent') }}</span>
          <span v-else-if="type === 'S'">{{ $t('general.sell') }}</span>
          {{ $t('general.RESIDENTIAL') }}
        </div>
        <div
          class="popup--item"
          v-for="(item, index) in propertyTypesResidential"
          :key="`residential-${index}`"
          @click="goToSearch(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-if="propertyTypesCommercial">
        <div class="popup--title">
          <span v-if="type === 'R'">{{ $t('general.rent') }}</span>
          <span v-else-if="type === 'S'">{{ $t('general.sell') }}</span>
          {{ $t('general.COMMERCIAL') }}
        </div>
        <div class="d-flex flex-wrap">
          <div
            class="popup--item"
            v-for="(item, index) in propertyTypesCommercial"
            :key="`commercial-${index}`"
            @click="goToSearch(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import auth from '@/mixins/auth.js';

export default {
  name: 'header-user',
  mixins: [auth],
  props: {
    type: {
      require: true,
    },
  },
  data: () => ({
    propertyTypesResidential: [],
    propertyTypesCommercial: [],
  }),
  mounted() {
    this.getPropertyTypesResidential();
    this.getPropertyTypesCommercial();
  },
  methods: {
    async getPropertyTypesResidential() {
      this.propertyTypesResidential = await this.$store.dispatch(
        'v2/masters/getPropertyTypesHeader',
        {
          category_id: 7,
          listing_type: this.type,
        },
      );
    },
    async getPropertyTypesCommercial() {
      this.propertyTypesCommercial = await this.$store.dispatch(
        'v2/masters/getPropertyTypesHeader',
        {
          category_id: 8,
          listing_type: this.type,
        },
      );
    },
    goToSearch(propertyType) {
      this.$store.commit('v2/search/SET_LISTING_TYPE', this.type);
      this.$store.commit('v2/search/SET_PROPERTY_TYPE_ID', propertyType.id);
      this.$store.commit('v2/search/SET_ACTIVE_PROPERTY_TYPES', [propertyType]);
      this.$store.dispatch('v2/search/goToSearchPage', {
        router: this.$router,
        params: this.$route.params,
      });
    },
  },
};
</script>
